<template>
  <b-col sm="12" md="3">
    <b-card no-body>
      <b-card-body>
        <b-row>
          <b-col sm="12">
            <h3>
              <router-link
                v-if="clickable"
                :to="{
                  name: 'HackathonViewIdea',
                  params: {
                    idea_id: id,
                    hackathon_id: hackathon_id,
                  },
                }"
                >{{ name }}</router-link
              >
              <a v-else>{{ name }}</a>
              &nbsp;

              <router-link
                v-if="is_creator"
                :to="{
                  name: 'HackathonRudIdea',
                  params: {
                    idea_id: id,
                    hackathon_id: hackathon_id,
                  },
                }"
              >
                <i class="fas fa-edit"></i>
              </router-link>
            </h3>
          </b-col>

          <b-col class="text-right"> </b-col>
        </b-row>
        <b-row>
          <b-col>
            <badge
              v-if="idea.hackathon.ideas_require_approval"
              :rounded="true"
              size="md"
              :type="approval_status_style"
            >
              {{ idea.approval.state.display_name }}
            </badge>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <p>{{ description }}</p>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <p
              v-if="
                (team.max_members > 0 && team.member_count > 0) ||
                is_part_of_team
              "
            >
              Team {{ team.member_count }}/{{ team.max_members }}
              &nbsp;
              <badge
                v-if="team.is_member"
                :rounded="true"
                size="md"
                type="info"
              >
                Your Team
              </badge>
            </p>
            <router-link
              v-if="clickable"
              :to="{
                name: 'HackathonViewIdea',
                params: {
                  idea_id: id,
                  hackathon_id: hackathon_id,
                },
              }"
            >
              Join this team!
            </router-link>
            <a v-else>Join this team!</a>
          </b-col>
        </b-row>
        <!-- <b-row>
                    <b-col>
                        <p class="float-right">
                            {{ 0 < likes && likes < 2 ? likes + " Like" : "" }}
                            {{ 1 < likes ? likes + " Likes" : "" }}
                            <i
                                v-if="!liked"
                                class="far fa-thumbs-up text-lg ml-1"
                                @click="update_likes('like')"
                            ></i>
                            <i
                                v-else
                                class="fas fa-thumbs-up text-lg ml-1"
                                @click="update_likes('unlike')"
                            ></i>
                        </p>
                    </b-col>
                </b-row> -->
      </b-card-body>
    </b-card>
  </b-col>
</template>

<script>
import {
  CREATE_LIKE_HACKATHON_IDEA,
  DELETE_LIKE_HACKATHON_IDEA,
} from "@/graphql/mutations";

import { Idea } from "@/ORM/Hackathon/Idea.js";

export default {
  name: "ListIdeaCard",
  emits: ["liked", "unliked", "new_like_id"],
  props: {
    id: {
      type: String,
      description: "The id of the idea",
      default: null,
    },
    idea: {
      type: Object,
      description: "The idea object",
      default() {
        return new Idea();
      },
    },
    hackathon_id: {
      type: String,
      description: "The id of the idea",
      default: null,
    },
    name: {
      type: String,
      description: "The name of the idea",
      default: null,
    },
    description: {
      type: String,
      description: "The short_description of the idea",
      default: null,
    },
    likes: {
      type: Number,
      description: "Number of likes for this idea",
      default: 0,
    },
    liked_id: {
      type: String,
      description: "The id of the like of the idea",
      default: null,
    },
    is_creator: {
      type: Boolean,
      description: "The user is the creator of this idea",
      default: false,
    },
    is_part_of_team: {
      type: Boolean,
      description: "If the  user is part of a team.",
      default: false,
    },
    team: {
      type: Object,
      description: "An object containing team information",
      default() {
        return {
          member_count: 0,
          max_members: 0,
          is_member: false,
        };
      },
    },
    clickable: {
      type: Boolean,
      description: "The Idea can be viewed",
      default: true,
    },
  },
  data() {
    return {
      liked: false,
    };
  },
  methods: {
    update_likes(action) {
      if (action == "like") {
        this.liked = true;
        this.$apollo
          .mutate({
            mutation: CREATE_LIKE_HACKATHON_IDEA,
            variables: {
              creator: $store.getters.getUser.id_b64,
              idea: [this.id],
            },
          })
          .then((res) => {
            console.log(res);
            if ("data" in res) {
              this.$emit("liked");
              this.$emit("new_like_id", res.data.likeCreateLike.like.id);
            }
          })
          .catch((err) => {
            this.liked = false;
            // TODO: We should raise a notification or something.
          });
      }
      if (action == "unlike") {
        this.liked = false;
        this.$apollo
          .mutate({
            mutation: DELETE_LIKE_HACKATHON_IDEA,
            variables: {
              id: this.liked_id,
            },
          })
          .then((res) => {
            console.log(res);
            if ("data" in res) {
              this.$emit("unliked");
              this.$emit("new_like_id", null);
            }
          })
          .catch((err) => {
            this.liked = true;
          });
      }
    },
  },
  computed: {
    approval_status_style() {
      if (this.idea.approval.state.name == "SUBMITTED") {
        return "danger";
      }
      if (this.idea.approval.state.name == "PENDING_SUBMISSION") {
        return "danger";
      }
      if (this.idea.approval.state.name == "NEEDS_REVIEW") {
        return "danger";
      }
      if (this.idea.approval.state.name == "REJECTED") {
        return "warning";
      }
      if (this.idea.approval.state.name == "APPROVED") {
        return "info";
      }
      return "danger";
    },
  },
  mounted() {
    if (this.liked_id) {
      this.liked = true;
    } else {
      this.liked = false;
    }
  },
};
</script>

<style></style>
