class TimeAuditedModel {
    constructor(data = {}) {
        this.created = data.created || null;
        this.modified = data.modified || null;
    }
}

class Service extends TimeAuditedModel {
    constructor(data = {}) {
        super(data);
        this.id = data.id || null;
        this.name = data.name || "";
    }
}

class Template extends TimeAuditedModel {
    constructor(data = {}) {
        super(data);
        this.id = data.id || null;
        this.service = data.service ? new Service(data.service) : null;
        this.uid = data.uid || null;
    }
}

class TemplateVersion extends TimeAuditedModel {
    constructor(data = {}) {
        super(data);
        this.id = data.id || null;
        this.template = data.template ? new Template(data.template) : null;
        this.active = data.active || false;
        this.version = data.version || 0;
        this.body = data.body || "";
        this.uid = data.uid || null;
    }
}

class Query extends TimeAuditedModel {
    constructor(data = {}) {
        super(data);
        this.id = data.id || null;
        this.uid = data.uid || null;
        this.user = data.user || null; // Assuming user is just an ID here. If it's an object, you might need another ORM for User.
        this.service = data.service ? new Service(data.service) : null;
        this.template_version = data.templateVersion
            ? new TemplateVersion(data.templateVersion)
            : null; // Changed to snake_case
        this.request_data = data.requestData || null; // Changed to snake_case
        this.success = data.success || false;
        this.errored = data.errored || false;
        this.errors = data.errors || null;
        this.response = data.response || null;
        this.finished = data.finished || null;

        


    }
}

const modelMapping = {
    service: Service,
    template: Template,
    template_version: TemplateVersion, // Changed to snake_case
    query: Query,
};

function processAiRelayData(inputObject) {
    for (let key in inputObject.data) {
        if (modelMapping[key]) {
            const ModelClass = modelMapping[key];

            // Handle array of instances (for "all" prefixed keys)
            if (key.startsWith("all") && inputObject.data[key].edges) {
                return inputObject.data[key].edges.map(
                    (edge) => new ModelClass(edge.node)
                );
            }

            // Handle single instance
            return new ModelClass(inputObject.data[key]);
        }
    }
    return null;
}

export { Service, Template, TemplateVersion, Query, processAiRelayData };
