import { Hackathon } from "@/ORM/Hackathon/Hackathon.js";
import { Approval } from "@/ORM/Global/Approvals.js";
import { User } from "@/ORM/Global/User.js";
import { Query } from "@/ORM/Global/Ai.js";

class Idea {
    constructor(data = {}) {
        // Convert camelCase to snake_case
        this.id = data.id || null;
        this.name = data.name || "";
        this.account = data.account || null;
        this.creator = data.creator ? new User(data.creator) : null;
        this.hackathon = data.hackathon
            ? new Hackathon(data.hackathon)
            : new Hackathon();
        this.approval = data.approval
            ? new Approval(data.approval)
            : new Approval();
        this.likes = data.likes || [];
        this.number_of_likes = data.numberOfLikes || 0;
        this.description = data.description || null;
        this.detail_description = data.detailDescription || null;
        this.ai_feedback =
            data.aiFeedback && data.aiFeedback.edges
                ? data.aiFeedback.edges.map((edge) => new Query(edge.node))
                : [];
        this.is_creator =
            typeof data.isCreator === "boolean" ? data.isCreator : null;
    }
}

const modelMapping = {
    hackathonIdeaIdea: Idea,
    allHackathonIdeaIdea: Idea,
};

function processIdeaRelayData(inputObject) {
    for (let key in inputObject.data) {
        if (modelMapping[key]) {
            const ModelClass = modelMapping[key];

            // Handle array of instances (for "all" prefixed keys)
            if (key.startsWith("allHackathon") && inputObject.data[key].edges) {
                return inputObject.data[key].edges.map(
                    (edge) => new ModelClass(edge.node)
                );
            }

            // Handle single instance
            return new ModelClass(inputObject.data[key]);
        }
    }
    return null;
}

export { Idea, processIdeaRelayData };
