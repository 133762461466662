import { User } from "@/ORM/Global/User.js";

class State {
    constructor(data = {}) {
        this.id = data.id || null;
        this.name = data.name || "";
        this.display_name = data.displayName || "";
        this.created = data.created || null;
        this.modified = data.modified || null;
    }
}

class ItemType {
    constructor(data = {}) {
        this.id = data.id || null;
        this.supported_states =
            data.supportedStates && data.supportedStates.edges
                ? data.supportedStates.edges.map((edge) => new State(edge.node))
                : [];
        this.created = data.created || null;
        this.modified = data.modified || null;
    }
}

class Approval {
    constructor(data = {}) {
        this.id = data.id || null;
        this.uid = data.uid || null;
        this.item_type =
            data.itemType && typeof data.itemType === "object"
                ? new ItemType(data.itemType)
                : new ItemType();
        this.is_closed = data.isClosed || false;
        this.state =
            data.state && typeof data.state === "object"
                ? new State(data.state)
                : new State();
        this.state_updates =
            data.stateUpdates && data.stateUpdates.edges
                ? data.stateUpdates.edges.map(
                      (edge) => new StateUpdate(edge.node)
                  )
                : [];
        this.created = data.created || null;
        this.modified = data.modified || null;
    }
}

class StateUpdate {
    constructor(data = {}) {
        this.id = data.id || null;
        this.approval =
            data.approval && typeof data.approval === "object"
                ? new Approval(data.approval)
                : new Approval();
        this.new_state =
            data.newState && typeof data.newState === "object"
                ? new State(data.newState)
                : new State();
        this.comment = data.comment || null;
        this.created_by = data.createdBy ? new User(data.createdBy) : null;
        this.created = data.created || null;
        this.modified = data.modified || null;
    }
}

const modelMapping = {
    state: State,
    approvalItemType: ItemType,
    allApprovalItemType: ItemType,
    approvalApproval: Approval,
    allApprovalApproval: Approval,
    approvalStateUpdate: StateUpdate,
    allApprovalStateUpdate: StateUpdate,
};

function processApprovalRelayData(inputObject) {
    for (let key in inputObject.data) {
        if (modelMapping[key]) {
            const ModelClass = modelMapping[key];

            // Handle array of instances (for "all" prefixed keys)
            if (key.startsWith("all") && inputObject.data[key].edges) {
                return inputObject.data[key].edges.map(
                    (edge) => new ModelClass(edge.node)
                );
            }

            // Handle single instance
            return new ModelClass(inputObject.data[key]);
        }
    }
    return null;
}

export { State, ItemType, Approval, StateUpdate, processApprovalRelayData };
