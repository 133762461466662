// models.js
import { User } from "@/ORM/Global/User.js";
import { Idea } from "@/ORM/Hackathon/Idea.js";

class Hackathon {
  constructor(data = {}) {
    this.id = data.id || "";
    this.uid = data.uid || null;
    this.creator = data.creator ? new User(data.creator) : null;
    this.organization = data.organization || null;
    this.name = data.name || "";
    this.description = data.description || null;
    this.detail_description = data.detailDescription || null;
    this.allowed_participants =
      data.allowedParticipants || Hackathon.AllowedParticipantOpts.PUBLIC;
    this.start_datetime = data.start_datetime || null;
    this.end_datetime = data.end_datetime || null;
    this.max_team_size = data.max_team_size || 5;
    this.status = data.status || Hackathon.StatusOpts.PLANNING;
    this.phase = data.phase || Hackathon.PhaseOpts.PLANNING;
    this.is_creator =
      typeof data.isCreator === "boolean" ? data.isCreator : null;
    this.is_judge = typeof data.isJudge === "boolean" ? data.isJudge : null;
    this.is_mentor = typeof data.isMentor === "boolean" ? data.isMentor : null;
    this.is_manager =
      typeof data.isManager === "boolean" ? data.isManager : null;

    this.ideas =
      data.ideas && data.ideas.edges
        ? data.ideas.edges.map((edge) => new Idea(edge.node))
        : [];
    this.has_goals = typeof data.hasGoals === "boolean" ? data.hasGoals : null;
    this.has_prizes =
      typeof data.hasPrizes === "boolean" ? data.hasPrizes : null;
    this.has_judges =
      typeof data.hasJudges === "boolean" ? data.hasGoals : null;
    this.ideas_require_approval =
      typeof data.ideasRequireApproval === "boolean"
        ? data.ideasRequireApproval
        : null;
  }

  // Static Enums
  static AllowedParticipantOpts = {
    PUBLIC: "public",
    INVITE_ONLY: "invite_only",
  };

  static StatusOpts = {
    PLANNING: "planning",
    ACTIVE: "active",
    COMPLETE: "COMPLETE",
    ARCHIVED: "archived",
    DELETED: "deleted",
  };

  static PhaseOpts = {
    PLANNING: "planning",
    REGISTRATION_OPEN: "registration_open",
    IDEA_SUBMISSION: "idea_submission",
    HACKING_STARTED: "hacking_started",
    HACKING_ENDED: "hacking_ended",
    TEAM_PITCHES: "team_pitches",
    JUDGING: "judging",
    COMPLETE: "complete",
  };
}

class ManagementTeamMember {
  constructor(data) {
    this.id = data.id || "";
    this.hackathon = data.hackathon || null;
    this.user = data.user ? new User(data.user) : new User();
    this.role = data.role || ManagementTeamMember.RoleOpts.ADMIN;
  }

  static RoleOpts = {
    ADMIN: "admin",
    OWNER: "owner",
  };
}

class ManagementTeamMemberInvite {
  constructor(data) {
    this.id = data.id || "";
    this.uid = data.uid || null;
    this.hackathon = data.hackathon || null;
    this.inviter = data.inviter || null;
    this.email = data.email || "";
    this.status = data.status || ManagementTeamMemberInvite.StatusOpts.PENDING;
    this.responded_user = data.responded_user || null;
  }

  static StatusOpts = {
    PENDING: "pending",
    ACCEPTED: "accepted",
    DECLINED: "declined",
  };
}

class CompetitionCategory {
  constructor(data) {
    this.id = data.id || "";
    this.hackathon = data.hackathon || null;
    this.name = data.name || null;
    this.default = data.default || false;
    this.submission_template = data.submission_template || null;
  }
}

class HackathonCount {
  constructor(data) {
    this.id = data.id || "";
    // Assuming it inherits from BaseCount and has fields from TimeAuditedModel
    this.createdAt = data.createdAt || null; // Assuming TimeAuditedModel has a createdAt field
    this.updatedAt = data.updatedAt || null; // Assuming TimeAuditedModel has an updatedAt field
    // ... add other fields from BaseCount
  }
}

const modelMapping = {
  allHackathonHackathon: Hackathon,
  hackathonHackathon: Hackathon,
  allHackathonManagementTeamMember: ManagementTeamMember,
  hackathonManagementTeamMember: ManagementTeamMember,
  allHackathonManagementTeamMemberInvite: ManagementTeamMemberInvite,
  hackathonManagementTeamMemberInvite: ManagementTeamMemberInvite,
  allHackathonCompetitionCategory: CompetitionCategory,
  hackathonCompetitionCategory: CompetitionCategory,
  allHackathonHackathonCount: HackathonCount,
  hackathonHackathonCount: HackathonCount,
  // Add other mappings here as needed
};

function processHackathonRelayData(inputObject, specificKey = null) {
  if (specificKey) {
    if (modelMapping[specificKey] && inputObject.data[specificKey]) {
      const ModelClass = modelMapping[specificKey];
      if (
        specificKey.startsWith("all") &&
        inputObject.data[specificKey].edges
      ) {
        return inputObject.data[specificKey].edges.map(
          (edge) => new ModelClass(edge.node)
        );
      } else {
        return new ModelClass(inputObject.data[specificKey]);
      }
    }
    return null;
  }
  for (let key in inputObject.data) {
    if (modelMapping[key]) {
      const ModelClass = modelMapping[key];
      // Handle array of instances (for "all" prefixed keys)
      if (!inputObject.data[key]) {
        return new ModelClass();
      }
      if (key.startsWith("all") && inputObject.data[key].edges) {
        return inputObject.data[key].edges.map(
          (edge) => new ModelClass(edge.node)
        );
      }

      // Handle single instance
      return new ModelClass(inputObject.data[key]);
    }
  }
  return null;
}

export {
  processHackathonRelayData,
  Hackathon,
  ManagementTeamMember,
  ManagementTeamMemberInvite,
  CompetitionCategory,
  HackathonCount,
};
