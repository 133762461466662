class User {
    constructor(data = {}) {
        this.id = data.id || null;
        this.first_name = data.firstName || ""; // Assuming GraphQL returns camelCase
        this.last_name = data.lastName || "";
        this.email = data.email || "";
        this.name = this.first_name + " " + this.last_name;
    }
}

const modelMapping = {
    user: User,
};

function processRelayData(inputObject) {
    for (let key in inputObject.data) {
        if (modelMapping[key]) {
            const ModelClass = modelMapping[key];

            // Handle array of instances (for "all" prefixed keys)
            if (key.startsWith("all") && inputObject.data[key].edges) {
                return inputObject.data[key].edges.map(
                    (edge) => new ModelClass(edge.node)
                );
            }

            // Handle single instance
            return new ModelClass(inputObject.data[key]);
        }
    }
    return null;
}

export { User, modelMapping, processRelayData };
